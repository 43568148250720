export default [
  { name: '首頁', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '' },
  { name: '商圈正核心', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '' },
  { name: '千坪大公園', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: -100 },
  { name: '萬坪綠水畔', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '' },
  { name: '最大Costco', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '' },
  { name: '競速生活', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: -100, mobileOffset: 80 },
  { name: '學府文化圈', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: -100, mobileOffset: 50 },
  { name: '名宅公設', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', mobileOffset: 80 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0 },
]